import React, { useState } from 'react';
import axios from 'axios';
import Header from './Header';

const ExcelUpload: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [downloadLink, setDownloadLink] = useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      setError('Please select an Excel file to upload.');
      return;
    }

    setLoading(true);
    setError(null);
    setDownloadLink(null);

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
        const token = localStorage.getItem('token');
      const response = await axios.post('https://toolex.herweck.de/api/upload-price-list-excel/', formData, {
        responseType: 'blob', // Important for handling the file download
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Token ${token}`, 
        },
      });

      // Create a download link for the zip file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      setDownloadLink(url);
    } catch (error) {
      setError('Failed to upload and process the file.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>   
    <Header/>
    <div className="max-w-lg mx-auto p-4">
      <h2 className="text-xl font-bold mb-4">Upload Excel File</h2>
      <input
        type="file"
        accept=".xls,.xlsx"
        onChange={handleFileChange}
        className="mb-4 w-full"
      />
      {selectedFile && (
        <div className="mb-4 text-gray-600">
          Selected File: {selectedFile.name}
        </div>
      )}
      {error && (
        <div className="mb-4 text-red-600">
          {error}
        </div>
      )}
      <button
        onClick={handleUpload}
        className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
        disabled={loading}
      >
        {loading ? 'Uploading...' : 'Upload and Process'}
      </button>

      {downloadLink && (
        <div className="mt-4">
          <a
            href={downloadLink}
            download="generated_excels.zip"
            className="text-blue-500 hover:underline"
          >
            Download Generated Files
          </a>
        </div>
      )}
    </div>
    </div>
  );
};

export default ExcelUpload;
