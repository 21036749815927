import React, { useState, useEffect } from 'react';
import axios from 'axios';

interface BatchSummary {
  batch_name: string;
  total_products: number;
  scraped_products: number;
}

const BatchSummaryComponent: React.FC = () => {
  const [batches, setBatches] = useState<BatchSummary[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [notAnalyzedBatches, setNotAnalyzedBatches] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);

  // Function to fetch batch data
  const fetchBatchSummary = () => {
    const token = localStorage.getItem('token');
  
    axios
      .get('https://toolex.herweck.de/api/batch-summary/', {
        headers: {
          Authorization: `Token ${token}`,  // Include the token in the Authorization header
        },
      })
      .then((response) => {
        setBatches(response.data);
        setNotAnalyzedBatches(response.data.not_analyzed_batches)  
        console.log(response.data)
        setLoading(false);
      })
      .catch((error) => {
        setError('Error fetching batch data');  // Handle errors
        setLoading(false);
      });
  };
  

  // Fetch data initially and set interval to update every 30s
  useEffect(() => {
    fetchBatchSummary();
    const interval = setInterval(() => {
      fetchBatchSummary();
    }, 3000); // 30 seconds
    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  // Calculate percentage of scraped products for a batch
  const getScrapedPercentage = (scraped: number, total: number): number => {
    return total > 0 ? Math.round((scraped / total) * 100) : 0;
  };

  // Filter to only include batches that are not fully scraped
  const incompleteBatches = batches.filter(
    (batch) => batch.scraped_products < batch.total_products
  );

  return (
    <div className="w-full max-h-64 overflow-auto"> {/* Adjust height and width */}
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : incompleteBatches.length > 0 ? (
        <div className="space-y-2">
          {incompleteBatches.map((batch) => {
            const scrapedPercentage = getScrapedPercentage(
              batch.scraped_products,
              batch.total_products
            );
            return (
              <div key={batch.batch_name} className="bg-gray-200 p-2 rounded">
                <div className="flex justify-between items-center mb-1">
                  <span className="text-sm font-medium">{batch.batch_name}</span>
                  <span className="text-xs">
                    {batch.total_products - batch.scraped_products} not scraped / {batch.total_products}
                  </span>
                </div>
                <div className="w-full bg-gray-300 h-2 rounded">
                  <div
                    className="bg-green-500 h-2 rounded"
                    style={{ width: `${scrapedPercentage}%` }}
                  ></div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <p>All batches are fully scraped!</p>
      )}
    </div>
  );
};

export default BatchSummaryComponent;
