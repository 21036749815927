import { Button } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';

interface Provider {
    provider_name: string | null;
    provider_id: string | null;
}

interface Classification {
    volume: string;
    amazon_margin: string;
    idealo_margin: string;
    online_since: string;
    avg_monthly_sold: string;
    avg_review: string;
    review_count: string;
    sub_category_rank: string;
    total_offers_idealo: string;
    color: string;
}

interface CurrentOffer {
    id: number;
    status: string;
    price: number | null;
    quantity: number | null;
    provider: Provider | null;
    Current_Amazon_Margin_EUR: number | null;
    Current_Idealo_Margin_EUR: number | null;
    Current_Idealo_Margin_Percentage: number | null;
    Current_Amazon_Margin_Percentage: number | null;

}

interface Product {
    id: number;
    EAN: string;
    ASIN: string | null;
    Title: string | null;
    manufacturer: string | null;
    color: string | null;
    Package_Volume: number | null;
    Package_Weight: number | null;
    Avg_Monthly_Sold: number | null;
    Review_Count: number | null;
    Avg_Review: number | null;
    Current_Amazon_Margin_EUR: number | null;
    Best_Margin_Idealo_EUR: number | null;
    Total_Offers_Idealo: number | null;
    Online_Since: string | null;
    Root_Category_Name: string | null;
    Sub_Category_Name: string | null;
    Latest_Sales_Rank_Sub_Category: number | null;
    current_offer: CurrentOffer | null;
    volume_category: string;
    classifications: Classification;
    score: number;
}

const CLASSIFICATION_COLORS = {
    'good': 'bg-green-500 text-white',
    'medium': 'bg-yellow-500 text-black',
    'bad': 'bg-red-500 text-white',
    'Unknown': 'bg-gray-300 text-gray-900'
};

interface BatchTableProps {
    data: Product[];
    batchName: string | null;
    notFoundData: Product[];
    onDataUpdate: (newData: Product[], newNotFoundData: Product[]) => void;
}

interface visiableColumn {
    status: string;
}

const STATUS_COLORS = {
    'Nicht bearbeitet': 'bg-gray-300 text-gray-900',
    'Ja': 'bg-green-500 text-white',
    'Nein': 'bg-red-500 text-white',
    'WVL': 'bg-yellow-500 text-black',
    'EK-Neu Verhandeln': 'bg-purple-500 text-white'
};

const BatchTable: React.FC<BatchTableProps> = ({ data, batchName, notFoundData,onDataUpdate }) => {
    const [products, setProducts] = useState<Product[]>([]);
    const [showNotFound, setShowNotFound] = useState(false);
    const [activeDropdown, setActiveDropdown] = useState<number | null>(null);
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc'); // Default to descending
    const [scoreFilter, setScoreFilter] = useState<string>('');
    const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
    const [isColumnSettingsOpen, setIsColumnSettingsOpen] = useState(false);


    useEffect(() => {
        const productsToShow = showNotFound ? notFoundData : data;
        // Always sort first
        const sortedProducts = [...productsToShow].sort((a, b) => {
            return sortDirection === 'desc' 
                ? b.score - a.score 
                : a.score - b.score;
        });
    
        // Then apply filter if it exists
        if (scoreFilter !== '') {
            const threshold = parseFloat(scoreFilter) / 10;
            const filtered = sortedProducts.filter(product => product.score >= threshold);
            setFilteredProducts(filtered);
        } else {
            setFilteredProducts(sortedProducts);
        }
    }, [data, notFoundData, showNotFound, sortDirection, scoreFilter]);

    const handleScoreFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value === '' || (/^\d+$/.test(value) && parseInt(value) <= 10)) {
            setScoreFilter(value);
        }
    };

    // Add toggle sort function
    const toggleSort = () => {
        setSortDirection(prev => prev === 'desc' ? 'asc' : 'desc');
    };

    const getClassificationColor = (classification: string) => {
        return CLASSIFICATION_COLORS[classification as keyof typeof CLASSIFICATION_COLORS] || CLASSIFICATION_COLORS['Unknown'];
    };

    // Helper function to format values
    const formatValue = (value: any, type: 'number' | 'date' | 'string' = 'string') => {
        if (value === null || value === undefined) return 'N/A';
        switch (type) {
            case 'number':
                return typeof value === 'number' ? value.toFixed(2) : 'N/A';
            case 'date':
                return value ? new Date(value).toLocaleDateString() : 'N/A';
            default:
                return value.toString();
        }
    };

    const handleStatusChange = async (product: Product, newStatus: string) => {
        if (!batchName) return;
    
        try {
            const token = localStorage.getItem('token');
            const response = await fetch('https://toolex.herweck.de/api/update-product-offer-status/', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                },
                body: JSON.stringify({
                    EAN: product.EAN,
                    status: newStatus,
                    batch_name: batchName,
                }),
            });
    
            if (response.ok) {
                // Update the data prop through a callback
                const updatedProduct = {
                    ...product,
                    current_offer: product.current_offer ? {
                        ...product.current_offer,
                        status: newStatus
                    } : null
                };
    
                // Update both data sources
                if (showNotFound) {
                    const updatedNotFoundData = notFoundData.map(p => 
                        p.EAN === product.EAN ? updatedProduct : p
                    );
                    // You'll need to add a prop for this
                    onDataUpdate(data, updatedNotFoundData);
                } else {
                    const updatedData = data.map(p => 
                        p.EAN === product.EAN ? updatedProduct : p
                    );
                    // You'll need to add a prop for this
                    onDataUpdate(updatedData, notFoundData);
                }
            }
        } catch (error) {
            console.error('Error updating status:', error);
        }
        setActiveDropdown(null);
    };

    const [columnVisibility, setColumnVisibility] = useState<Record<string, boolean>>({
        status: true,
        Title:false,
        EAN: true,
        Manufacturer: true,
        Color:true,
        Volume:true,
        Amazon_Margin_Eur : true,
        Amazon_Margin_Procent:true,
        Idealo_Margin_Eur:true,
        Idealo_Margin_Procent:true,
        Online_Since:true,
        Monthly_Sold:true,
        Reviews:true,
        Review_Count:true,
        Sub_Category:true,
        Category_Rank:true,
        Total_Offers_Idealo:true,
        Score:true

    })

    const handleExportExcel = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch('https://toolex.herweck.de/api/export-excel/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                },
                body: JSON.stringify({
                    data: filteredProducts,
                    batchName: batchName,
                    columns: columnVisibility
                }),
            });

            if (response.ok) {
                // Get the filename from the Content-Disposition header if available
                const contentDisposition = response.headers.get('Content-Disposition');
                let filename = 'export.xlsx';
                if (contentDisposition) {
                    const filenameMatch = contentDisposition.match(/filename=(.*?)(;|$)/);
                    if (filenameMatch) {
                        filename = filenameMatch[1];
                    }
                }

                // Get the blob from the response
                const blob = await response.blob();
                
                // Use file-saver to save the file
                saveAs(blob, filename);
            } else {
                console.error('Export failed:', await response.text());
            }
        } catch (error) {
            console.error('Error exporting to Excel:', error);
        }
    };

    console.log(batchName,filteredProducts,columnVisibility);
    return (
        <div className="space-y-4">
        <div className="flex items-center justify-between p-4 bg-white rounded-lg shadow">
            <div className="flex items-center space-x-4">
                <span className="font-medium">
                    {showNotFound ? "Showing Incomplete Products" : "Showing Complete Products"}
                </span>
                <button
                    onClick={() => setShowNotFound(!showNotFound)}
                    className={`px-4 py-2 rounded-md transition-colors duration-200 ${
                        showNotFound 
                            ? 'bg-yellow-500 text-white hover:bg-yellow-600' 
                            : 'bg-blue-500 text-white hover:bg-blue-600'
                    }`}
                >
                    Toggle {showNotFound ? 'Complete' : 'Incomplete'} Products
                </button>
                <button
                    onClick={() => setSortDirection(prev => prev === 'desc' ? 'asc' : 'desc')}
                    className="px-4 py-2 rounded-md bg-gray-500 text-white hover:bg-gray-600 transition-colors duration-200"
                >
                    Score Order: {sortDirection === 'desc' ? '↓ High to Low' : '↑ Low to High'}
                </button>
                <div className="flex items-center space-x-2">
                    <label className="text-sm font-medium text-gray-700">
                        Min Score (0-10):
                    </label>
                    <input
                        type="number"
                        min="0"
                        max="10"
                        value={scoreFilter}
                        onChange={handleScoreFilterChange}
                        className="w-20 px-2 py-1 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="e.g., 7"
                    />
                    {scoreFilter && (
                        <span className="text-sm text-gray-600">
                            (Showing ≥ {(parseInt(scoreFilter)/10).toFixed(1)})
                        </span>
                    )}
                    {scoreFilter && (
                        <button
                            onClick={() => setScoreFilter('')}
                            className="text-gray-500 hover:text-gray-700"
                        >
                            ✕
                        </button>
                    )}
                </div>
                
            </div>
            <div className="text-sm text-gray-600 flex flex-col items-end">
                <div>
                    Showing {filteredProducts.length} {showNotFound ? 'incomplete' : 'complete'} products
                </div>
                {filteredProducts.length > 0 && (
                    <div className="text-xs text-gray-500">
                        Score Range: {formatValue(Math.min(...filteredProducts.map(p => p.score)), 'number')} - {formatValue(Math.max(...filteredProducts.map(p => p.score)), 'number')}
                    </div>
                )}
            </div>
            <button
                        onClick={handleExportExcel}
                        className="px-4 py-2 rounded-md bg-green-600 text-white hover:bg-green-700 transition-colors duration-200 flex items-center space-x-2"
                    >
                        <svg 
                            className="w-5 h-5" 
                            fill="none" 
                            stroke="currentColor" 
                            viewBox="0 0 24 24"
                        >
                            <path 
                                strokeLinecap="round" 
                                strokeLinejoin="round" 
                                strokeWidth="2" 
                                d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" 
                            />
                        </svg>
                        <span>Export to Excel</span>
                    </button>
        </div>
        <div className="relative">
    <button 
        onClick={() => setIsColumnSettingsOpen(!isColumnSettingsOpen)}
        className="bg-gray-100 p-2 rounded-md hover:bg-gray-200 flex items-center space-x-2"
    >
        <span>Column Settings</span>
        <svg 
            className={`w-4 h-4 transition-transform ${isColumnSettingsOpen ? 'rotate-180' : ''}`} 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24"
        >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
        </svg>
    </button>

    {isColumnSettingsOpen && (
        <div className="absolute top-full mt-2 right-0 z-50 bg-white p-4 rounded-lg shadow-lg max-w-md">
            <h3 className="text-lg font-semibold mb-4">Toggle Columns</h3>
            <div className="grid grid-cols-2 gap-3">
            {Object.entries(columnVisibility).map(([key, value]) => (
                    <div 
                        key={key} 
                        className="flex items-center p-2 hover:bg-gray-50 rounded-md cursor-pointer"
                        onClick={() => setColumnVisibility({
                            ...columnVisibility,
                            [key]: !columnVisibility[key]
                        })}
                    >
                        <div className={`w-5 h-5 rounded border mr-3 flex items-center justify-center
                            ${value ? 'bg-blue-500 border-blue-500' : 'border-gray-300'}`}
                        >
                            {value && (
                                <svg 
                                    className="w-3 h-3 text-white" 
                                    fill="none" 
                                    stroke="currentColor" 
                                    viewBox="0 0 24 24"
                                >
                                    <path 
                                        strokeLinecap="round" 
                                        strokeLinejoin="round" 
                                        strokeWidth="2" 
                                        d="M5 13l4 4L19 7"
                                    />
                                </svg>
                            )}
                        </div>
                        <span className="text-sm text-gray-700">
                            {key.replace(/_/g, ' ')}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    )}
</div>

            {/* Table */}
            <div className="overflow-x-auto max-h-[1000px]">
                <table className="min-w-full text-xs text-gray-700 border-collapse">
                    <thead className="bg-gray-900 text-white sticky top-0 z-50">
                    <tr>
                        {Object.entries(columnVisibility).map(([key, visible]) => (
                            <th 
                                key={key}
                                className={`${visible ? '' : 'hidden'} px-2 py-1 border-b border-gray-600`}
                            >
                                {key.replace(/_/g, ' ')}
                            </th>
                        ))}
                    </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-300">
                        {filteredProducts.map((product, index) => (
                            <tr key={index} className="hover:bg-gray-100">
                                {Object.entries(columnVisibility).map(([key, visible]) => {
                                    // Helper to get the appropriate content for each column
                                    const getColumnContent = () => {
                                        switch(key) {
                                            case 'status':
                                                return (
                                                    <div className="relative">
                                                        <button
                                                            onClick={() => setActiveDropdown(activeDropdown === index ? null : index)}
                                                            className={`px-2 py-1 rounded ${
                                                                STATUS_COLORS[product.current_offer?.status as keyof typeof STATUS_COLORS] || 
                                                                STATUS_COLORS['Nicht bearbeitet']
                                                            }`}
                                                        >
                                                            {product.current_offer?.status || 'Nicht bearbeitet'}
                                                        </button>
                                                        
                                                        {activeDropdown === index && (
                                                            <div className="absolute z-50 mt-1 bg-white border rounded-md shadow-lg">
                                                                {Object.keys(STATUS_COLORS).map((status) => (
                                                                    <button
                                                                        key={status}
                                                                        onClick={() => handleStatusChange(product, status)}
                                                                        className={`block w-full text-left px-4 py-2 hover:bg-gray-100 ${
                                                                            STATUS_COLORS[status as keyof typeof STATUS_COLORS]
                                                                        }`}
                                                                    >
                                                                        {status}
                                                                    </button>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            case 'EAN':
                                                return product.EAN;
                                            case 'Title':
                                                return product.Title;
                                            case 'Manufacturer':
                                                return formatValue(product.manufacturer);
                                            case 'Color':
                                                return formatValue(product.color);
                                            case 'Volume':
                                                return (
                                                    <>
                                                        {formatValue(product.Package_Volume, 'number')}
                                                        <span className="text-xs ml-1">({product.volume_category})</span>
                                                    </>
                                                );
                                            case 'Amazon_Margin_Eur':
                                                return `${formatValue(product.current_offer?.Current_Amazon_Margin_EUR, 'number')} €`;
                                            case 'Amazon_Margin_Procent':
                                                return `${formatValue(product.current_offer?.Current_Amazon_Margin_Percentage, 'number')}%`;
                                            case 'Idealo_Margin_Eur':
                                                return `${formatValue(product.current_offer?.Current_Idealo_Margin_EUR, 'number')} €`;
                                            case 'Idealo_Margin_Procent':
                                                return `${formatValue(product.current_offer?.Current_Idealo_Margin_Percentage, 'number')}%`;
                                            case 'Online_Since':
                                                return formatValue(product.Online_Since, 'date');
                                            case 'Monthly_Sold':
                                                return formatValue(product.Avg_Monthly_Sold, 'number');
                                            case 'Reviews':
                                                return formatValue(product.Avg_Review, 'number');
                                            case 'Review_Count':
                                                return formatValue(product.Review_Count, 'number');
                                            case 'Sub_Category':
                                                return formatValue(product.Sub_Category_Name);
                                            case 'Category_Rank':
                                                return formatValue(product.Latest_Sales_Rank_Sub_Category, 'number');
                                            case 'Total_Offers_Idealo':
                                                return formatValue(product.Total_Offers_Idealo, 'number');
                                            case 'Score':
                                                return formatValue(product.score, 'number');
                                            default:
                                                return 'N/A';
                                        }
                                    };

                                    // Helper to get classification color for columns that need it
                                    const getColumnClassification = () => {
                                        switch(key) {
                                            case 'Color':
                                                return getClassificationColor(product.classifications?.color);
                                            case 'Volume':
                                                return getClassificationColor(product.classifications?.volume);
                                            case 'Amazon_Margin_Procent':
                                                return getClassificationColor(product.classifications?.amazon_margin);
                                            case 'Idealo_Margin_Procent':
                                                return getClassificationColor(product.classifications?.idealo_margin);
                                            case 'Online_Since':
                                                return getClassificationColor(product.classifications?.online_since);
                                            case 'Monthly_Sold':
                                                return getClassificationColor(product.classifications?.avg_monthly_sold);
                                            case 'Reviews':
                                                return getClassificationColor(product.classifications?.avg_review);
                                            case 'Review_Count':
                                                return getClassificationColor(product.classifications?.review_count);
                                            case 'Category_Rank':
                                                return getClassificationColor(product.classifications?.sub_category_rank);
                                            case 'Total_Offers_Idealo':
                                                return getClassificationColor(product.classifications?.total_offers_idealo);
                                            case 'Score':
                                                return product.score >= 0.7 ? 'bg-green-500 text-white' :
                                                    product.score >= 0.4 ? 'bg-yellow-500 text-black' :
                                                    'bg-red-500 text-white';
                                            default:
                                                return '';
                                        }
                                    };

                                    return (
                                        <td 
                                            key={key}
                                            className={`${visible ? '' : 'hidden'} px-2 py-1 border-b border-gray-200 ${getColumnClassification()}`}
                                        >
                                            {getColumnContent()}
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default BatchTable;