import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import ParentComponent from './components/ParentComponent';
import ExcelUpload from './components/PricelistComponent';
import Login from './components/Login';
import Logout from './components/Logout';

const App: React.FC = () => {
    const [token, setToken] = React.useState<string | null>(localStorage.getItem('token'));

    return (
        <Router>
            <Routes>
                {/* Redirect / to /batch */}
                <Route path="/" element={<Navigate to="/batch" />} />
                <Route path="/login" element={<Login setToken={setToken} />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/batch" element={token ? <ParentComponent /> : <Login setToken={setToken} />} />
                <Route path="/preislisten" element={token ? <ExcelUpload /> : <Login setToken={setToken} />} />
            </Routes>
        </Router>
    );
};

export default App;
