import React from 'react';

interface BatchSelectorProps {
    batches: string[];
    onSelectBatch: (batch: string) => void;
}

const BatchSelector: React.FC<BatchSelectorProps> = ({ batches, onSelectBatch }) => {
    return (
        <div className="mb-4">
            <h2 className="text-lg font-semibold mb-2">Select Batch</h2>
            <select 
                className="w-full p-2 border rounded-md"
                onChange={(e) => onSelectBatch(e.target.value)}
                defaultValue=""
            >
                <option value="" disabled>Choose a batch</option>
                {batches.map((batch, index) => (
                    <option key={index} value={batch}>
                        {batch}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default BatchSelector;