import React, { useState, useEffect } from 'react';
import axios from 'axios';

interface Score {
  volume: number;
  amazon_margin: number;
  idealo_margin: number;
  online_since: number;
  avg_monthly_sold: number;
  avg_review: number;
  review_count: number;
  sub_category_rank: number;
  total_offers_idealo: number;
  color: number;
}

interface ScoreComponentProps {
  onSubmit: () => void;  // Callback function for handling submission
}

const ScoreComponent: React.FC<ScoreComponentProps> = ({ onSubmit }) => {
  const [scores, setScores] = useState<Score>({
    volume: 0,
    amazon_margin: 0,
    idealo_margin: 0,
    online_since: 0,
    avg_monthly_sold: 0,
    avg_review: 0,
    review_count: 0,
    sub_category_rank: 0,
    total_offers_idealo: 0,
    color: 0
  });

  const [total, setTotal] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);

  // Fetch the current scores from the API when the component mounts
  useEffect(() => {
    const token = localStorage.getItem('token');
  
    axios.get('https://toolex.herweck.de/api/score/', {
      headers: {
        Authorization: `Token ${token}`,  // Include the token in the Authorization header
      },
    })
    .then((response) => {
      // Convert each score to percentage (x 100)
      const fetchedScores = response.data;
      setScores({
        volume: fetchedScores.volume * 100,
        amazon_margin: fetchedScores.amazon_margin * 100,
        idealo_margin: fetchedScores.idealo_margin * 100,
        online_since: fetchedScores.online_since * 100,
        avg_monthly_sold: fetchedScores.avg_monthly_sold * 100,
        avg_review: fetchedScores.avg_review * 100,
        review_count: fetchedScores.review_count * 100,
        sub_category_rank: fetchedScores.sub_category_rank * 100,
        total_offers_idealo: fetchedScores.total_offers_idealo * 100,
        color: fetchedScores.color * 100,
      });
    })
    .catch((error) => console.error(error));
  }, []);
  

  // Update the total whenever scores change
  useEffect(() => {
    const totalValue = Object.values(scores).reduce((acc, value) => acc + value, 0);
    setTotal(totalValue);

    // Set error if total is not 100
    if (totalValue !== 100) {
      setError('Total must be exactly 100%');
    } else {
      setError(null);
    }
  }, [scores]);

  // Handle input changes
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const numericValue = parseFloat(value);
    setScores({
      ...scores,
      [name]: isNaN(numericValue) ? 0 : numericValue
    });
  };

  // Handle form submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (total === 100) {
        // Convert back to decimal for API
        const updatedScores = Object.fromEntries(
            Object.entries(scores).map(([key, value]) => [key, value / 100])
        );

        // Retrieve the token from localStorage
        const token = localStorage.getItem('token');

        // Ensure the token is available
        if (!token) {
            alert('You are not authenticated!');
            return;
        }

        // Send the PUT request with the token included in the headers
        axios.put('https://toolex.herweck.de/api/score/', updatedScores, {
            headers: {
                Authorization: `Token ${token}`,  // Include the token in the Authorization header
            },
        })
        .then(() => {
            alert('Scores updated successfully!');
            onSubmit(); // Trigger the parent component's onSubmit function to close the modal
        })
        .catch((error) => {
            console.error('Error updating scores:', error);
            alert('Failed to update scores.');
        });
    } else {
        alert('Total score must equal 100%.');
    }
};


  return (
    <div className="max-w-6xl mx-auto mt-10">
      <form
        onSubmit={handleSubmit}
        className={`p-5 border rounded ${total !== 100 ? 'border-red-500' : 'border-green-500'}`}
      >
        <h2 className="text-2xl mb-4">Adjust Scores</h2>
  
        {/* Grid layout for 3 fields per row */}
        <div className="grid grid-cols-3 gap-4">
          {Object.keys(scores).map((key) => (
            <div key={key} className="mb-4">
              <label className="block text-gray-700 capitalize mb-2">{key.replace(/_/g, ' ')}</label>
              <input
                type="number"
                name={key}
                value={scores[key as keyof Score]}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                min="0"
                max="100"
                step="0.1"
              />
            </div>
          ))}
        </div>
  
        <div className="text-lg font-semibold mb-4">
          Total: <span className={total !== 100 ? 'text-red-500' : 'text-green-500'}>{total}%</span>
        </div>
  
        {error && <p className="text-red-500 mb-4">{error}</p>}
  
        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 px-4 rounded disabled:opacity-50"
          disabled={total !== 100}
        >
          Submit
        </button>
      </form>
    </div>
  );  
};

export default ScoreComponent;
