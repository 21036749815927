import React, { useState } from 'react';
import axios from 'axios';

const ExcelUploader: React.FC = () => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [batchName, setBatchName] = useState<string>(''); // To store the batch name
    const [providerId, setProviderId] = useState<string>(''); // To store provider ID
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false); // For loading state

    // Handle file selection
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
        }
    };

    // Handle batch name change
    const handleBatchNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBatchName(event.target.value);
    };

    // Handle provider ID change
    const handleProviderIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setProviderId(event.target.value);
    };

    // Handle form submission
    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (!selectedFile) {
            alert("Please select a file.");
            return;
        }

        if (!providerId) {
            alert("Please provide a valid provider ID.");
            return;
        }

        setIsSubmitting(true);

        // Prepare the form data
        const formData = new FormData();
        formData.append('excel_file', selectedFile);

        // Use the file name if no batch name is provided
        const finalBatchName = batchName || selectedFile.name;
        formData.append('batch_name', finalBatchName);
        formData.append('provider_id', providerId); // Append the provider ID

        try {
            // Retrieve the token from localStorage
            const token = localStorage.getItem('token');

            // Send POST request to the backend with the token in the headers
            const response = await axios.post('https://toolex.herweck.de/api/upload-excel/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Token ${token}`,  // Include the token in the Authorization header
                },
            });

            // Handle successful response
            alert(`Batch created successfully: ${response.data.message}`);
        } catch (error) {
            console.error('Error uploading file:', error);
            alert('Error uploading file. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="p-4 bg-white rounded-lg shadow-md">
            <h2 className="text-lg font-semibold mb-4">Upload Excel File</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="file">
                        Select Excel File:
                    </label>
                    <input
                        type="file"
                        id="file"
                        accept=".xlsx, .xls"
                        onChange={handleFileChange}
                        className="border rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline"
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="batchName">
                        Batch Name (Optional):
                    </label>
                    <input
                        type="text"
                        id="batchName"
                        placeholder="Enter batch name or leave blank"
                        value={batchName}
                        onChange={handleBatchNameChange}
                        className="border rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline"
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="providerId">
                        Provider ID:
                    </label>
                    <input
                        type="text"
                        id="providerId"
                        placeholder="Enter provider ID"
                        value={providerId}
                        onChange={handleProviderIdChange}
                        className="border rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline"
                        required
                    />
                </div>

                <div className="flex justify-between items-center">
                    <button
                        type="submit"
                        disabled={isSubmitting}
                        className="bg-indigo-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:bg-indigo-600"
                    >
                        {isSubmitting ? 'Uploading...' : 'Upload'}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ExcelUploader;
