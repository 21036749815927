import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BatchSelector from './BatchSelector';
import BatchTable from './BatchTable';
import BatchSummaryComponent from './BatchSummaryComponent';
import ExcelUploader from './ExcelUploader';
import ScoreComponent from './ScoreComponent';
import Header from './Header';

interface Provider {
    provider_name: string | null;
    provider_id: string | null;
}

interface Classification {
    volume: string;
    amazon_margin: string;
    idealo_margin: string;
    online_since: string;
    avg_monthly_sold: string;
    avg_review: string;
    review_count: string;
    sub_category_rank: string;
    total_offers_idealo: string;
    color: string;
}

interface CurrentOffer {
    id: number;
    status: string;
    price: number | null;
    quantity: number | null;
    provider: Provider | null;
    Current_Amazon_Margin_EUR: number | null;
    Current_Idealo_Margin_EUR: number | null;
    Current_Idealo_Margin_Percentage: number | null;
    Current_Amazon_Margin_Percentage: number | null;
}

interface Product {
    id: number;
    EAN: string;
    ASIN: string | null;
    Title: string | null;
    manufacturer: string | null;
    color: string | null;
    Package_Volume: number | null;
    Package_Weight: number | null;
    Avg_Monthly_Sold: number | null;
    Review_Count: number | null;
    Avg_Review: number | null;
    Current_Amazon_Margin_EUR: number | null;
    Best_Margin_Idealo_EUR: number | null;
    Total_Offers_Idealo: number | null;
    Online_Since: string | null;
    Root_Category_Name: string | null;
    Sub_Category_Name: string | null;
    Latest_Sales_Rank_Sub_Category: number | null;
    current_offer: CurrentOffer | null;
    volume_category: string;
    classifications: Classification;
    score: number;
}

interface Batch {
    id: number;
    name: string;
    timestamp: string;
    status: string;
    processing_status: string;
    complete_products: Product[];
    incomplete_products: Product[];
}


const ParentComponent: React.FC = () => {
    const [selectedBatch, setSelectedBatch] = useState<string | null>(null);
    const [batches, setBatches] = useState<string[]>([]);
    const [selectedBatchData, setSelectedBatchData] = useState<Batch | null>(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);
    const [isScoreOpen, setIsScoreOpen] = useState<boolean>(false);
    const token = localStorage.getItem('token');

    const [data, setData] = useState<Product[]>([]);
    const [notFoundData, setNotFoundData] = useState<Product[]>([]);

    const handleDataUpdate = (newData: Product[], newNotFoundData: Product[]) => {
        setData(newData);
        setNotFoundData(newNotFoundData);
    };

    // Fetch all batch names
    useEffect(() => {
        axios.get('https://toolex.herweck.de/api/batch-names/', {
            headers: {
                Authorization: `Token ${token}`,
            }
        })
        .then((response) => {
            console.log('Batch Names:', response.data);
            setBatches(response.data);
        })
        .catch((error) => {
            console.error('Error fetching batch names: ', error);
        });
    }, []);

    // Fetch specific batch data when selected
    useEffect(() => {
        if (selectedBatch) {
            axios.get(`https://toolex.herweck.de/api/batch/?batch_name=${encodeURIComponent(selectedBatch)}`, {
                headers: {
                    Authorization: `Token ${token}`,
                }
            })
            .then((response) => {
                console.log('Selected Batch Data:', response.data);
                setSelectedBatchData(response.data[0]);
            })
            .catch((error) => {
                console.error('Error fetching batch details: ', error);
            });
        }
    }, [selectedBatch]);

    const handleScoreSubmit = () => {
        setIsScoreOpen(false);
    };

    return (
        <div>
            <Header />

            <div className="relative flex">
                {/* Sidebar */}
                <div
                    className={`fixed top-0 left-0 h-full bg-gray-100 shadow-lg transition-transform duration-300 ease-in-out 
                    ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} w-64 p-4 pt-16 space-y-4`}
                >
                    <BatchSelector 
                        batches={batches}
                        onSelectBatch={(batch) => setSelectedBatch(batch)} 
                    />
                    <BatchSummaryComponent />
                    {selectedBatchData && (
                        <div className="flex justify-end mb-4 px-4">
                            {/* Add any batch data manipulation components here */}
                        </div>
                    )}
                    <ExcelUploader />
                    <button
                        className="mt-4 bg-gray-500 text-white py-2 px-4 rounded-md hover:bg-gray-600"
                        onClick={() => setIsScoreOpen(true)}
                    >
                        Open Score Settings
                    </button>
                </div>

                {/* Toggle Button */}
                <button 
                    className="fixed top-4 left-4 bg-gray-300 text-gray-700 p-2 rounded-md shadow-md hover:bg-gray-600 focus:outline-none w-12 h-12 z-50"
                    onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                >
                    {isSidebarOpen ? (
                        <span className="text-xl">✖</span>
                    ) : (
                        <div className="space-y-1">
                            <span className="block w-6 h-0.5 bg-gray-700"></span>
                            <span className="block w-6 h-0.5 bg-gray-700"></span>
                            <span className="block w-6 h-0.5 bg-gray-700"></span>
                        </div>
                    )}
                </button>

                {/* Main Content */}
                <div
                    className={`flex-1 transition-all duration-300 ease-in-out ${isSidebarOpen ? 'ml-64' : 'ml-0'} p-8`}
                >
                    {selectedBatchData && (
                        <BatchTable 
                            data={selectedBatchData.complete_products}
                            batchName={selectedBatch}
                            notFoundData={selectedBatchData.incomplete_products}
                            onDataUpdate={handleDataUpdate}
                        />
                    )}
                </div>
            </div>

            {/* ScoreComponent Modal */}
            {isScoreOpen && (
                <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full">
                        <h2 className="text-2xl font-bold mb-4">Score Settings</h2>
                        <ScoreComponent onSubmit={handleScoreSubmit} />
                        <button
                            className="mt-4 bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-600"
                            onClick={() => setIsScoreOpen(false)}
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ParentComponent;